import React from 'react';


import './membership.css'

const Membership = () => {
    return (
        <body id="membership" class="memb__tile">
            <h1 class="memb_title">Become A Member</h1>

            <section class="membership">
                <body>Discord: https://discord.gg/cRYjCuFb</body>
                <body>Instagram: https://www.instagram.com/dsc.uf/</body>
            </section>
        </body>
    )
}

export default Membership;
